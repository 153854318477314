export enum StudentLoanEligibilityResult {
  Eligible = 'eligible',
  NotEligible = 'notEligible',
  Error = 'error',
  CannotRetrieveMethodData = 'cannotRetrieveMethodData',
  SessionExpired = 'sessionExpired',
}

export enum StudentLoanEmployerResult {
  NonProfit = 'nonProfit',
  ForProfit = 'forProfit',
  NotSure = 'notSure',
}

export enum StudentLoanNotEnrolledResult {
  Applying = 'applying',
  WontApprove = 'wontApprove',
  DifficultToApply = 'difficultToApply',
  WontSaveMoney = 'wontSaveMoney',
}

export enum StudentLoanPslfWontSaveMoneyResult {
  IsStudent = 'IsStudent',
  Other = 'Other',
}

export enum StudentLoanSavingsResult {
  Next = 'next',
  NotNow = 'notNow',
}

export enum StudentLoanBookAppointmentResult {
  Schedule = 'schedule',
  NotNow = 'notNow',
}

export enum StudentLoanSaveRepaymentPlanResult {
  Enrolled = 'enrolled',
  NotEnrolled = 'notEnrolled',
  HaveNotHeard = 'haveNotHeard',
}

export enum StudentLoanBookAdvisorResult {
  Schedule = 'schedule',
  NotNow = 'notNow',
}

export enum StudentLoanApplyOptionsResult {
  Schedule = 'schedule',
  NotNow = 'notNow',
  ApplyWithUs = 'applyWithUs',
  ApplyOnYourOwn = 'applyOnYourOwn',
}

export enum StudentLoanApplyOnYourOwnResult {
  ApplyWithUs = 'applyWithUs',
  Done = 'done',
}

export enum StudentLoanPayingResult {
  Success = 'success',
  Failure = 'failure',
}

export enum PaymentPageResult {
  PayrollDeduction = 'payrollDeduction',
  NotNow = 'notNow',
}

export enum MohelaResult {
  EnrolledWantForgiveness = 'EnrolledWantForgiveness',
  AppliedWantEnrollment = 'AppliedWantEnrollment',
  NotApplied = 'NotApplied',
  NotSure = 'NotSure',
}

export enum ComeBackWhenDueResult {
  Done = 'done',
  TalkToAnAdvisor = 'talkToAnAdvisor',
}

export enum PaymentSituationResult {
  Yes = 'Yes',
  ImAStudent = 'imAStudent',
  Other = 'other',
}

export type StudentLoanForgivenessResults =
  | StudentLoanEligibilityResult
  | StudentLoanEmployerResult
  | StudentLoanNotEnrolledResult
  | StudentLoanPslfWontSaveMoneyResult
  | StudentLoanSavingsResult
  | StudentLoanBookAppointmentResult
  | StudentLoanSaveRepaymentPlanResult
  | StudentLoanSavingsResult
  | StudentLoanBookAdvisorResult
  | StudentLoanApplyOptionsResult
  | StudentLoanApplyOnYourOwnResult
  | StudentLoanPayingResult
  | PaymentPageResult
  | ComeBackWhenDueResult
  | MohelaResult
  | PaymentSituationResult;
