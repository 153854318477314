import React from 'react';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ReactComponent as SignIcon } from 'images/sign-icon.svg';
import { ButtonType } from 'components/Button/Button';
import { StudentLoanApplyOnYourOwnResult } from 'enums/StudentLoanForgivenessFlowResults';

import styles from './ApplyOnYourOwn.module.scss';

const ApplyOnYourOwn = ({ navigationInfo, handleNext }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <FormContainer icon={<SignIcon />} title="Apply on Your Own">
      <div className={styles.buttonContainer}>
        <p>
          At Plannery, your success is our success. The journey to achieve student loan forgiveness is long and winding,
          but you’ve come to the right place. The right guidance can bring repayment within reach.
        </p>

        <div className={styles.cardContainer}>
          <p className={styles.heading}>Tips for Success:</p>
          <ol>
            <li>
              <p>Update your contact information</p>
              <ul>
                <li>
                  Review your{' '}
                  <a href="https://studentaid.gov/fsa-id/sign-in" target="_blank" rel="noreferrer">
                    studentaid.gov
                  </a>{' '}
                  profile
                </li>
                <li>Review your loan servicer’s website</li>
              </ul>
            </li>

            <li>
              <p>Understand your repayment options</p>
              <ul>
                <li>Determine your options with the government’s loan simulator</li>
                <li>
                  Determine your eligibility for an Income Driven Repayment (IDR) plan, which could drastically reduce
                  your payment
                </li>
              </ul>
            </li>

            <li>
              <p>Consolidate your student loans</p>
              <ul>
                <li>
                  Apply for a Direct Consolidation Loan by April 30, 2024 if you hold FFEL, Perkins, or Health Education
                  Assistance Loan (HEAL) Program loan to get the full benefits of the one-time account adjustment.
                </li>
                <li>
                  For additional guidance, please visit{' '}
                  <a href="https://studentaid.gov" target="_blank" rel="noreferrer">
                    studentaid.gov
                  </a>
                </li>
              </ul>
            </li>
          </ol>
        </div>

        <p>
          We welcome you to apply on your own, though Plannery cannot be held responsible for incorrect filings,
          resulting rejections and consequential disputes. If you'd like support from our advisors, consider applying
          with us.
        </p>

        <div className={styles.buttonContainer}>
          <Button onClick={() => handleNext(StudentLoanApplyOnYourOwnResult.Done)}>Done</Button>
          <Button type={ButtonType.Secondary} onClick={() => handleNext(StudentLoanApplyOnYourOwnResult.ApplyWithUs)}>
            Apply with Plannery
          </Button>
        </div>
      </div>
    </FormContainer>
  </>
);

export default ApplyOnYourOwn;
